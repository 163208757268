<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>세트별통계</h1></div>
    <common-area-search
        :row="2"
        :colspan="2"
        @search="allApiCall"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <common-multi-drop-down
              ref="cmpyCdRef"
              @change="value=>{
                  propsCmpyCd = value
                  $refs.setRef.widget().value('')
                  allApiCall()
                }"
              :params="{ 'sort[0].dir': 'asc', 'sort[0].field': 'cmpyNm',searchCmpyGbnGrup : [Constant.efs.cmpyGbn.CLIENT_COMPANY] }"
              :default-index="0"
              :data-columns="[{ field: 'cmpyNm', title: '회사명', width: '60%' },{ field: 'cmpyCd', title: '회사코드', width: '40%' }]"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/company`"
              :data-value-field="'cmpyCd'"
              :data-text-field="'cmpyNm'"
          >
          </common-multi-drop-down>
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>조회일</label>
        <ul>
          <kendo-datepicker
              ref="dtRef"
              :value="new Date()"
              :disabled="true"
              :format="'yyyy/MM/dd'"
              :placeholder="'년/월/일'"
              @change="allApiCall"
          ></kendo-datepicker>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>세트</label>
        <ul>
          <common-multi-drop-down
              ref="setRef"
              :params="{ searchCmpyCd : propsCmpyCd }"
              :url="`${ApiConfig.efsDomain}/efs/drop-down/set`"
              :data-value-field="'setCd'"
              :data-text-field="'setNm'"
              @change="allApiCall"
          >
          </common-multi-drop-down>
        </ul>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->

    <div class="box_flex mgt10">
      <div class="contents_wrap w30">
        <div class="small_title_wrap">
          <h2>회수입고수</h2>
        </div>
        <div>
          <kendo-chart :render-as="'canvas'"
                       :chart-area="{background : '', height:250}"
                       :pannable-lock="'y'"
                       :transitions="inTransitions"
                       :series="[
                        {
                          type: inRetChartData.series.type,
                          data: inRetChartData.series.data,
                          axis: inRetChartData.series.axis,
                          visible : inRetChartData.series.visible,
                          categoryField: inRetChartData.series.categoryField,
                          name: constant.inRet,
                          field : inRetChartData.series.field,
                          color: inRetChartData.series.color,
                          tooltip : inRetChartData.series.tooltip
                        },]"
                       :category-axis="{justified: true}"
                       :value-axis="[
                         inRetChartData.valueAxis
                       ]"
                       :legend="{visible : false}"
                       :theme="'sass'">
          </kendo-chart>
        </div>
      </div>
      <div class="contents_wrap w30 mg0">
        <div class="small_title_wrap">
          <h2>세트 출고수</h2>
        </div>        
        <div>
          <kendo-chart :render-as="'canvas'"
                       :chart-area="{background : '', height:250}"
                       :series="[{
                          type: outChartData.series.type,
                          data: outChartData.series.data,
                          axis: outChartData.series.axis,
                          visible : outChartData.series.visible,
                          categoryField: outChartData.series.categoryField,
                          field : outChartData.series.field,
                          color: outChartData.series.color,
                          tooltip : outChartData.series.tooltip
                        }]"
                       :category-axis="{
                         justified: true,
                       }"
                       :value-axis="[outChartData.valueAxis]"
                       :legend-visible="false"
                       :theme="'sass'">
          </kendo-chart>
        </div>
      </div>
      <div class="contents_wrap w30 mgl10">
          <div class="small_title_wrap">
          <h2>세트 출고순위</h2>
          </div>           
          <div>
            <kendo-chart :render-as="'canvas'"
                        :chart-area="{background : '', height:250}"
                        :pannable-lock="'y'"
                        :series="[{
                              type: setOutRankChartData.series.type,
                              data: setOutRankChartData.series.data,
                              axis: setOutRankChartData.series.axis,
                              visible : setOutRankChartData.series.visible,
                              categoryField: setOutRankChartData.series.categoryField,
                              field : setOutRankChartData.series.field,
                              color: setOutRankChartData.series.color,
                              tooltip : setOutRankChartData.series.tooltip
                            }]"
                        :category-axis="{justified: true}"
                        :value-axis="[setOutRankChartData.valueAxis]"
                        :legend="{visible:true}"
                        :theme="'sass'">
            </kendo-chart>
          </div>
        </div>
    </div>
    <div class="box_flex mgt10">
      <div class="contents_wrap w15">
        <div class="small_title_wrap">
        <h2>품목 비율</h2>
        </div>           
        <div class="box_graph w100" style="width:100%; height:70%">
          <div class="inner-content">
            <span v-if="itemChartData.totalCount > 0" class="s_txt" style="top:48%">총 품목</span>
            <span v-if="itemChartData.totalCount > 0" class="txt_per" style="top:55%">{{ itemChartData.totalCount }}EA</span>
          </div>
          <kendo-chart
              :data-source="itemChartData.itemPercentInfoList"
              :series="[{ type: 'donut', field : 'cnt', holeSize: 50,}]"
              :tooltip="{ visible : itemChartData.totalCount !== 0, template : `#= dataItem.itemNm # : #= kendo.format('{0:N0}', dataItem.cnt) #EA&nbsp` }"
              :chart-area="{background : '', height:300, margin: { top : -0, left : -0, right :-0, bottom:-0 }}"
              :legend-visible="false"
              :theme="'sass'">
          </kendo-chart>
        </div>
      </div>
      <div class="contents_wrap w15 mg0 mgr10">
        <div class="small_title_wrap">
        <h2>상품 비율</h2>
        </div>           
        <div class="box_graph w100" style="width:100%; height:70%">
          <div class="inner-content">
            <span v-if="goodsChartData.totalCount > 0" class="s_txt" style="top:48%">총 상품</span>
            <span v-if="goodsChartData.totalCount > 0" class="txt_per" style="top:55%">{{ goodsChartData.totalCount }}EA</span>
          </div>
          <kendo-chart
              :data-source="goodsChartData.goodsPercentInfoList"
              :series="[{ type: 'donut', field : 'cnt', holeSize: 50,}]"
              :tooltip="{ visible : goodsChartData.totalCount !== 0, template : `#= dataItem.goodsNm # : #= kendo.format('{0:N0}', dataItem.cnt) #EA&nbsp` }"
              :chart-area="{background : '', height:300, margin: { top : -0, left : -0, right :-0, bottom:-0 }}"
              :legend-visible="false"
              :theme="'sass'">
          </kendo-chart>
        </div>
      </div>
      <div class="box_flex mgl10 w60">
        <div class="contents_wrap w50">
        <div class="small_title_wrap">
        <h2>개별 상품 출고량 TOP5</h2>
        </div> 
        <div class="small_title_wrap">
          <kendo-grid
              :height="280"
              :sortable-mode="'multiple'"
              :navigatable="false"
              :reorderable="true"
              :column-menu="false"
              :resizable="true"
              :selectable="false"
              :data-source="goodsOutRankList"
              :columns="gridColumns"
              :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
          ></kendo-grid>
        </div>
      </div>
        <div class="contents_wrap w50">
           <div class="small_title_wrap">
              <h2>
              세트별 출고량 <button @click="$refs.stkItemListWindowRef.kendoWidget().center().open()"  class="mid_btn orange mgl10">세트/품목별 수불현황 </button>
             </h2>        
          </div>          
          <kendo-grid
              :height="280"
              :class="'mgt10'"
              :sortable-mode="'multiple'"
              :navigatable="false"
              :reorderable="true"
              :column-menu="false"
              :resizable="true"
              :selectable="false"
              :data-source="setOutCumulativeList"
              :columns="setOutCumulativeGridColumns"
              :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
          ></kendo-grid>
        </div>
      </div>
    </div>
    <kendo-window
        ref="stkItemListWindowRef"
        :title="'세트/품목별 재고현황'"
        :modal="true"
        style="display:none; height: 720px; min-width: 1500px"
        :width="1700"
        @open="e=>{ isWindowOpen = true }"
        @close="e=>{ isWindowOpen = false }"
    >
      <component
          :is="isWindowOpen ? 'StkSetList' : null"
          :default-cmpy-cd="propsCmpyCd"
      ></component>
    </kendo-window>
  </div>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersEfsUtil from '@/common/etners.efs.util'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import StkSetList from './StkSetList.vue'
import Constant from '@/common/constant'

export default {
  name: 'ReportSetList',
  components : {
    StkSetList
  },
  methods: {
    getInRet : function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/set/in`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.setInRetChartData(response.data.resultData.inStat)
      })
    },
    setInRetChartData : function (responseChartData){
      const vm = this
      let resultChartData = _.cloneDeep(responseChartData)

      resultChartData.forEach((item, i) => {
        let isChangeMonth = false
        if(i === 0 || item.inReqDate.split('/')[1] !== responseChartData[i-1]?.inReqDate?.split('/')[1]){
          isChangeMonth = true
        }
        if(isChangeMonth){
          item.category = Number(item.inReqDate.split('/')[1]) + '/' + Number(item.inReqDate.split('/')[2])
        }else {
          item.category = Number(item.inReqDate.split('/')[2])
        }
        item.MMdd = item.inReqDate.split('/')[1] + '/' + item.inReqDate.split('/')[2]
      });

      let max = 0
      let min = 99999
      let maxMinGapCnt = 0
      let majorUnit = 0

      if(resultChartData.length > 0){
        // 차트의 구분선을 5개로 변경하기위한 코드
        let maxCnt = resultChartData.reduce(function(prev, current) {return (prev.inCnt > current.inCnt) ? prev : current}).inCnt
        let minCnt = resultChartData.reduce(function(prev, current) {return (prev.inCnt < current.inCnt) ? prev : current}).inCnt
        maxMinGapCnt =  maxCnt - minCnt
        let num = 10 ** (maxMinGapCnt.toString().length === 1 ? 1 : maxMinGapCnt.toString().length - 1)
        // 최대값의 2번째 자릿수에서 올림한 수를 chart의 최대값으로 한다(올림하지 않는경우 최대값과 데이터값이 일치하는 데이터의 UI가 일부 잘림)
        max = Math.ceil(maxCnt * 1.001 / num) * num
        min = Math.floor(minCnt * 0.999 / num) * num
        // 구분선의 개수를 5로 지정(5칸)
        majorUnit = (max - min) / 5
        // 최대값이 5보다 작은 경우 왼쪽 보조값이 0 0 0 1 1건으로 표시되는것을 방지
        if(maxCnt < 5){
          max = 5
          min = 0
          majorUnit = 1
        }
      }
      vm.inRetChartData  = {
        series : {
          type: 'line',
          data: resultChartData,
          visible : true,
          categoryField: 'category',
          field : 'inCnt',
          color: '#4b79d8',
          tooltip : {
            visible: true,
            background : '#ffffff',
            template : `#= dataItem.MMdd # : #= kendo.format('{0:N0}', value) #개 &nbsp`
          }
        },
        valueAxis : {
          visible: true,
          min : min,
          max : max,
          majorUnit : majorUnit,
          labels : {
            position : 'start',
            template : `#= kendo.format('{0:N0}', value) # 개 `
          }
        }
      }
    },
    getOutRet : function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/set/out`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.setOutChartData(response.data.resultData.outStat)
      })
    },
    setOutChartData : function (responseChartData){
      const vm = this
      let resultChartData = _.cloneDeep(responseChartData)

      resultChartData.forEach((item, i) => {
        let isChangeMonth = false
        let itemDt = item.outReqDate
        if(i === 0 || itemDt.split('/')[1] !== responseChartData[i-1]?.outReqDate?.split('/')[1]){
          isChangeMonth = true
        }
        if(isChangeMonth){
          item.category = Number(itemDt.split('/')[1]) + '/' + Number(itemDt.split('/')[2])
        }else {
          item.category = Number(itemDt.split('/')[2])
        }
        item.MMdd = itemDt.split('/')[1] + '/' + itemDt.split('/')[2]
      });

      let max = 0
      let min = 99999
      let maxMinGapCnt = 0
      let majorUnit = 0

      if(resultChartData.length > 0){
        // 차트의 구분선을 5개로 변경하기위한 코드
        let maxCnt = resultChartData.reduce(function(prev, current) {return (prev.outCnt > current.outCnt) ? prev : current}).outCnt
        let minCnt = resultChartData.reduce(function(prev, current) {return (prev.outCnt < current.outCnt) ? prev : current}).outCnt
        maxMinGapCnt =  maxCnt - minCnt
        let num = 10 ** (maxMinGapCnt.toString().length === 1 ? 1 : maxMinGapCnt.toString().length - 1)
        // 최대값의 2번째 자릿수에서 올림한 수를 chart의 최대값으로 한다(올림하지 않는경우 최대값과 데이터값이 일치하는 데이터의 UI가 일부 잘림)
        max = Math.ceil(maxCnt * 1.001 / num) * num
        min = Math.floor(minCnt * 0.999 / num) * num
        // 구분선의 개수를 5로 지정(5칸)
        majorUnit = (max - min) / 5
        // 최대값이 5보다 작은 경우 왼쪽 보조값이 0 0 0 1 1건으로 표시되는것을 방지
        if(maxCnt < 5){
          max = 5
          min = 0
          majorUnit = 1
        }
      }
      vm.outChartData  = {
        series : {
          type: 'line',
          data: resultChartData,
          visible : true,
          categoryField: 'category',
          field : 'outCnt',
          tooltip : {
            visible: true,
            background : '#ffffff',
            template : `#= dataItem.MMdd # : #= kendo.format('{0:N0}', value) #개 &nbsp`
          }
        },
        valueAxis : {
          visible: true,
          min : min,
          max : max,
          majorUnit : majorUnit,
          labels : {
            position : 'start',
            template : `#= kendo.format('{0:N0}', value) # 개 `
          }
        }
      }
    },
    getGoodsPercentCnt: function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/set/goods-percent`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.goodsChartData = response.data.resultData
        if(vm.goodsChartData.totalCount === 0){
          vm.goodsChartData.goodsPercentInfoList.push({ itemNm : '데이터없음', cnt : 1, color : '#efefef'})
        }
      })
    },
    getItemPercentCnt: function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/set/item-percent`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.itemChartData = response.data.resultData
        if(vm.itemChartData.totalCount === 0){
          vm.itemChartData.itemPercentInfoList.push({ itemNm : '데이터없음', cnt : 1, color : '#efefef'})
        }
      })
    },
    getSetOutRank : function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/set/out-rank/set`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.setSetOutRankChartData(response.data.resultData.slice(0,5))
      })
    },
    setSetOutRankChartData : function (responseChartData){
      const vm = this
      let resultChartData = _.cloneDeep(responseChartData)

      let max = 0
      let min = 99999
      let maxMinGapCnt = 0
      let majorUnit = 0

      if(resultChartData.length > 0){
        // 차트의 구분선을 5개로 변경하기위한 코드
        let maxCnt = resultChartData.reduce(function(prev, current) {return (prev.outCnt > current.outCnt) ? prev : current}).outCnt
        let minCnt = resultChartData.reduce(function(prev, current) {return (prev.outCnt < current.outCnt) ? prev : current}).outCnt
        maxMinGapCnt =  maxCnt - minCnt
        let num = 10 ** (maxMinGapCnt.toString().length === 1 ? 1 : maxMinGapCnt.toString().length - 1)
        // 최대값의 2번째 자릿수에서 올림한 수를 chart의 최대값으로 한다(올림하지 않는경우 최대값과 데이터값이 일치하는 데이터의 UI가 일부 잘림)
        max = Math.ceil(maxCnt * 1.001 / num) * num
        min = Math.floor(minCnt * 0.999 / num) * num
        // 구분선의 개수를 5로 지정(5칸)
        majorUnit = (max - min) / 5
        // 최대값이 5보다 작은 경우 왼쪽 보조값이 0 0 0 1 1건으로 표시되는것을 방지
        if(maxCnt < 5){
          max = 5
          min = 0
          majorUnit = 1
        }
      }

      vm.setOutRankChartData  = {
        series : {
          type: 'bar',
          data: resultChartData,
          visible : true,
          categoryField: 'setNm',
          field : 'outCnt',
          tooltip : {
            visible: true,
            background : '#ffffff',
            template : `#= kendo.format('{0:N0}', value) #개 &nbsp`
          }
        },
        valueAxis : {
          visible: true,
          min : min,
          max : max,
          majorUnit : majorUnit,
          labels : {
            position : 'end',
            template : `#= kendo.format('{0:N0}', value) # 개 `
          }
        }
      }
    },
    getGoodsOutRankList : function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/set/out-rank/goods`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.goodsOutRankList = EtnersEfsUtil.setRowNumber(response.data.resultData.slice(0,5),'ASC')
      })
    },
    getSetOutCumulative : function (){
      const vm = this
      let param = {
        ...vm.searchFilterParam()
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/report/set/out-cumulative`,param)
      .then((response) => {
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }
        vm.setOutCumulativeList = response.data.resultData
      })
    },
    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 회사, 조회일, 품목,
       * */
      refs.cmpyCdRef?.widget().value('')
      refs.dtRef.kendoWidget().value(new Date())
      refs.setRef?.widget().value('')
      this.propsCmpyCd = ''
      this.allApiCall()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      //회사명
      const cmpyCd = refs.cmpyCdRef?.widget().value()
      if (cmpyCd) {searchFilterOptions.searchCmpyCd = cmpyCd}

      //조회일
      const dt = refs.dtRef?.kendoWidget().value()
      if (!!dt) {
        searchFilterOptions.searchYYYYMMDD = EtnersDateTimeUtil.dateToYYYYMMDD(dt)
      }
      //품목
      const itemCd = refs.setRef?.widget().value()
      if (itemCd) {searchFilterOptions.searchSetCd = itemCd}

      return searchFilterOptions ?? undefined
    },
    allApiCall: function () {
      const vm = this
      vm.getInRet()
      vm.getOutRet()
      vm.getGoodsPercentCnt()
      vm.getItemPercentCnt()
      vm.getSetOutRank()
      vm.getGoodsOutRankList()
      vm.getSetOutCumulative()
    },
  },
  data () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    const constant = {
      complete: '완료',
      req: '신청',
      expect: '예정',
      new: '신품',
      renew: '재생',
      outComplete: '출고완료',
      outReq: '출고신청',
      day: 'DAY',
      month: 'MONTH',
      inNew: '신품입고',
      inRet: '회수',
      outStatus: {
        req: '02',
        complete: '03',
        cancel: '04'
      },
      renewGbn: {
        new: '01',
        renew: '02'
      }
    }
    return {
      Constant,
      constant : constant,
      ApiConfig : ApiConfig,
      lastRouteParams : {},
      isWindowOpen : false,
      propsCmpyCd : '',
      inTransitions : true,
      inRetChartData : {
        series : {},
        valueAxis : {}
      },
      inNewChartData : {
        series : {},
        valueAxis : {}
      },
      outChartData : {
        series : {},
        valueAxis : {}
      },
      stkChartData : {
        series : {},
        valueAxis : {}
      },
      goodsChartData : {
        totalCount : 0
      },
      itemChartData : {
        totalCount : 0
      },
      setOutRankChartData : {
        series : {},
        valueAxis : {}
      },
      goodsOutRankList : [],
      setOutCumulativeList : [],
      newStkStatDataSource : [],
      renewStkStatDataSource : [],
      inType: 'inNew',
      gridColumns: [
        {
          field: 'goodsNm',
          title: '상품명',
          width: '15%',
          headerAttributes: headerAttributes,
        },
        {
          field: 'rowNumber',
          title: '순위',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'outCnt',
          title: '출고량',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.outCnt)}`
          }
        }
      ],
      setOutCumulativeGridColumns: [
        {
          field: 'setNm',
          title: '세트명',
          width: '15%',
          headerAttributes: headerAttributes,
        },
        {
          field: 'outCnt',
          title: '출고량',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: rightAttributes,
          template: function (dataItem) {
            return `${EtnersCommonUtil.numberWithCommas(dataItem.outCnt)}`
          }
        }
      ],
      chartColor : [
        '#81ECEC'
        ,'#19ba68'
        ,'#74B9FF'
        ,'#FAB1A0'
        ,'#FF7675'
        ,'#A29BFE'
        ,'#FD79A8'
        ,'#00B894'
        ,'#FDCB6E'
        ,'#00CEC9'
        ,'#E17055'
        ,'#0984E3'
        ,'#D63031'
        ,'#6C5CE7'
        ,'#E84393'
      ],
    }
  },
}
</script>
